import React from "react"

const WilliamHill = () => (
  <section>
    <h1>Profile - William Hill PLC (TODO: dates???) 2018, 2019</h1>
    <p>TODO: summary. William Hill was one the earliest bookmaking chains after legalisation (history).
      Rise expansion into online. Digital transformation into technology business.
      Strategic decisions to build and own intellectual property.
    </p><p>
    Sportsbooks - mostly wager on sporting events such as racing and dogs, football but support
    other novelty wagers. A levy on gambling supports horse racing.
  </p>

    <section>
      <h2>William Hill Retail</h2>
      http://www.williamhillplc.com/about/our-businesses/retail/
      <p>
        Size of network. Volume. 4000..? Probably need to do some research over the period.
      </p><p>
      Digital transformation into technology business, decision to build and own intellectual property
      to add value over competitors.
    </p><p>
      Backbone of the business is traditional specialist horse racing and the dogs.
      Horse racing
      Online has richer support for football and other sports which are
    </p><p>
      Backbone of the business is traditional specialist horse racing and the dogs.
      Football and other team sports are traded using statistical models, mostly during the game.
      This enriches many people's lives by allowing them a friendly punt down the pub on their phone.
      A win pays for a round of drinks to celebrate.
    </p><p>
      Bookmakers are tradition here wrapped up with the Working Class and sport. Especially sports betting
      which has a high degree of skill. Today, bookies are the few places left on the high street which
      are warm, offer a tea or coffee, the loo and hours of sports on the big screens all the for price of a 10p accr.
      There's a traditional rich and earthy vocabulary associated with retail bookies - accers, trixies, union jacks
      and so on, as well as an eccentric cast of characters from the gutter to royalty. Horse Racing is the sport of
      Kings and Queen of ever Class from Gypsy to Her Brittanic Majesty.
    </p><p>
      Gambling is entertainment. People enjoy setting their wits, and winning tastes all the better when there's a
      stake.
      William Hill were a pioneer at bringing the traditions enjoyed by a few to a wider, mass market. More
      people enjoy a flutter but with less reliance on a small number of higher volume customers bookies have
      a greater incentive (backed by regulation) to detect problem behaviour before they cause long term harm.
    </p>
      <h3>Distributed Book Making (Liability Viewer)</h3>
      <p>
        The nature of horse racing means that
      </p>
      <p>
        Highly successful programmer, delivering highest margins on the high street. Key competitive advantage
        which drove the growth and forced consolidation of competitors.
      </p>
      <h3>Kiosk</h3>
      <p>
        William Hill has invested heavily in technology both to bring online innovations to the high street
        - through an innovative kiosk capable of bringing an online, in game experience to people looking
        for some glamour. Shops with an updated, contemporary feel but still welcoming to the traditional crowd.
      </p>
      <p>
        Development of an innovative self-service kiosk able to bring online experiences to high street punters
        was a key part of this strategy. In order to do this, it was necessary to break a supply monopoly
        with new designs and technologies. This approach was been highly successful,
        with innovations such as in playing betting and account spanning mobile, online and in shop allowing
        customers to experience
      </p>
      <p>
        Strategic decision to develop and own a Kiosk. Aims to
      </p>
      <p>
        Horse racing in Britain has a lot of depth. Unlike countries with standard tracks, in the British Isles, every
        race course is different and every race is run in different conditions. The draw can be important. Jockey enjoy
        considerable freedom, and so on. There are a rich variety of bets and ways that bet can be placed.
      </p><p>
      Unlike football wagers, which are almost always simple propositions, horse racing bookmaking is complex.
      By including a rich, visually appealing user experience similar to online football on a kiosk alongside football.
      Horse racing has been on the rise. The levy funding more prizes which in turn funds more trainers and recreational
      owners. Widening the appeal to a new crowd, beyond the Grand National to Cheltemhem and the other festivals.
    </p><p>
      Lean delivery, agile approach, contemporary technology mix. Driven by maximizing business value.
      High profile
    </p>
      <p>
        Front end javascript, react, angular running in browser on bespoke kiosk hardware with integration in Python.
        Data analytics using splunk.
      </p>
      <p>
        Backed by distributed microservices based on Spring Boot, both locally in store and in data centres.
        Docker. Puppet. Linux.
      </p><p>
      Highly successful, key driver of business value. See notes to city...?
    </p>
      <p>Awards..? City...?</p>
      <p>I've been involved twice. Once at the start, developing the JavaScript/React/AngularHJS front end.
        Then back as Principal when Tech Up North Ltd was brought in to development microservices and enrich
        the management tablet experience.
      </p>
    </section>

    <section>
      <h2>William Hill Global</h2>
      http://www.williamhillplc.com/about/our-businesses/william-hill-us/
      <p>
        Strategic drive to use own technology developed for UK market to expand into international markets.
        Online but also retail in NV. Driven by existing consumer demand, internet enabled people who wished to
        lay
      </p>
      <p>
        Legislative changes. Highly successful. Evidence led but political tide turned against the success
        and popularity when other economic areas were struggling. Campaining groups.
      </p>
      <h2>William Hill USA</h2>
      <p>Already legal in some states most famously Nevada. William Hill already had a business interest in Las Vegas,
        with a US business in Nevada both online and retail, through.
        The software developed for the retail kiosk programme (TODO: link...?) had already been used as the basis
        for an Nevada kiosk product.
      </p>
      <p>US Supreme Court ruling. State by state, states rights. Quite a different culture from
        the UK, traditional sports betting mediated by casinos already well know to states regulators.</p>
      <p>
        This ruling allowed all states to set their own rules and regulations. Those state legislatures who decided to
        allow
        legal sports betting each had their own particular aims and goals in mind .
        Success meant working with regulators in each state to gain approval, ensuring that the product offered
        met the aims and goals of that state as well as satisfying patrons.
      </p>
      <p>
        The first state was New Jersey potted history, casinos, blue colar, casinos, experience of cleaning up
        and legalising Atlantic City etc. Pushed expansion. Also saw sports wagering as a key additional source
        of funds as well as helping to reduce organised crime.
      </p>
      <p>
        The approach adopted by William Hill was well aligned with corporate strategy. Lean and agile methodologies
        would be used to adapt the existing online sportsbook.
      </p><p>
      Teams of highly skill experts would be assembled for the initial push, back filled by employees
      as the product matured.
    </p><p>
      In the emergent US market, a new betting engine. NV betting engine as a tactical, to be replaced by an in house
      betting engine.
    </p><p>
      Tech Up North Ltd was asked to get involved as a subcontractor, aqcuiring, adapting and transforming the data from
      the NV betting engine to model the input from the data feeds into existing online.
    </p><p>
      The challenge was that the NV betting engine was stable and reliable but ran on a stack. The data flow
      characteristics were quite different - output parallel message based data feeds including via websockets
      to browsers as against a mini-computer batch processing model in a emerging regulatory environment which
      demanded that the data is timely.
    </p><p>
      As might be expected, full technical specifications did not exist for either the input or the output. Most of the
      effort
      would be solving problems, discovering viable solutions and proving using automation that they worked.
      A small team of specialists was assembled.
    </p><p>
      Emerged that the wagering culture in the states very different to the UK, and that the offerings would
      need to be adapted. The best place to do most of the data work was in this transformation layer.
    </p><p>
      Development capacity problems meant need to be flexible, keeping a number of horses in the race.
      We opted to put a parallel cyclic fetch-and-process strategy, hoping that a move efficient message driven
      feed might be available later. The call was made to take this strategy into production, so we needed to
      expand a lot of effort working on throughput. Data volumes were relatively large, given the constraints of
      the systems in the mix.
    </p><p>
      Trading had great ambitions, and had funded expansion of the markets available beyond the ones in NV.
      This development was expidited, arriving in time for go live but caused challenges with just-in-time
      integration. I decided that -> pivot hours, split, working closely with key personelle from trading
      and the US.
    </p><p>
      automation, hand in hand with (TODO: link).
    </p>
      <p>Awards...?</p>
    </section>
  </section>
)

export default WilliamHill