import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WilliamHill from "../components/wh/william_hill"

const WilliamHillPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>

    <WilliamHill/>
  </Layout>
)

export default WilliamHillPage
